import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 42, width: 86, mr: 2 }}>
      <svg
        viewBox="0 0 113 34"
        
        xmlns="http://www.w3.org/2000/svg"
      >
          <g id="s_logo_g">
              <g>
                  <path d="M4,15.3c0.9-0.1,1.4,0.4,1.8,1.1C6.1,16.8,6.4,17,6.9,17c1.6,0,3.3-0.1,4.9-0.1c0.3,0,0.4-0.2,0.4-0.4
			c0.1-0.8,0.5-1.6,1.1-2.1c0.7-0.6,1.4-1.3,2.4-1.6c1-0.3,2-0.2,2.8,0.4c0.6,0.4,1.1,0.8,1.6,1.2c0.6,0.5,1,1.3,1.2,2.1
			c0.1,0.3,0.2,0.4,0.6,0.4c1.6,0,3.3,0,4.9,0.1c0.3,0,0.4-0.2,0.5-0.4c0.3-0.7,0.8-1.2,1.6-1.3c1-0.2,1.7,0.3,2.2,1
			c0.4,0.6,0.4,1.6-0.1,2.3c-0.5,0.7-1.3,1.1-2.1,0.9c-0.8-0.1-1.4-0.6-1.7-1.3c0-0.1-0.2-0.3-0.4-0.3c-1.7,0-3.3,0-5,0
			c-0.3,0-0.5,0.1-0.5,0.4c-0.2,1.3-1.1,2.1-2,2.8c-0.8,0.7-1.7,1.2-2.8,1.3c-0.8,0-1.3-0.4-1.8-0.8c-0.7-0.5-1.3-1-1.9-1.6
			c-0.5-0.5-0.6-1.1-0.7-1.8c0-0.3-0.2-0.4-0.5-0.4C10,18,8.2,18,6.3,18c-0.3,0-0.4,0.2-0.6,0.4c-0.4,0.8-1,1.1-1.9,1.1
			c-0.9,0-1.4-0.5-1.8-1.2c-0.5-1,0.1-2.5,1.2-2.9C3.5,15.3,3.8,15.3,4,15.3z M13.4,17.5c0,0.2,0,0.4,0.1,0.6c0.3,1.2,1.1,2,2.2,2.6
			c0.8,0.5,1.6,0.4,2.4-0.1c0.8-0.5,1.4-1.2,1.8-1.9c0.2-0.3,0.3-0.7,0.3-1c0-1.2-0.6-2.1-1.6-2.9c-0.6-0.5-1.3-0.9-2.2-0.7
			C15,14.3,13.4,15.8,13.4,17.5z M3.3,17.4c0,0.6,0.3,1,0.8,1c0.4,0,0.8-0.5,0.8-0.9c0-0.5-0.4-1-0.9-1C3.6,16.5,3.3,16.8,3.3,17.4z
			 M30.2,17.3c0-0.4-0.5-0.9-0.9-0.9c-0.4,0-0.7,0.5-0.7,0.9c0,0.5,0.4,1,0.7,1C29.7,18.3,30.2,17.8,30.2,17.3z"/>
                  <path d="M15.2,28.7c1.1-1.1,2.3-1,3.4-0.2c1.4-1.3,2.7-2.5,4-3.7c0.5-0.5,1.1-1,1.7-1.5c0.5-0.4,0.9-0.5,1.5-0.1
			c0.1-0.2,0.3-0.5,0.4-0.7c0.6-0.9,1.3-1.4,2.4-1.2c0.9,0.1,1.6,0.7,2,1.6c0.4,1.1-0.2,2.6-1.4,3.1c-1.1,0.5-2.4,0-3-1.1
			c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.2-0.3-0.4-0.2c-0.3,0.2-0.6,0.5-0.9,0.7c-1.6,1.4-3.1,2.7-4.7,4.1c-0.2,0.2-0.5,0.2-0.8,0.4
			c0.1,0.2,0.2,0.5,0.3,0.8c0.3,1.1-0.5,2.5-1.5,2.9c-1.2,0.4-2.4-0.1-3-1.3c-0.3-0.6-0.1-1.2,0-1.9c0-0.1,0-0.3-0.1-0.4
			c-1.8-1.5-3.7-3.1-5.5-4.6c-0.4-0.3-0.8-0.6-1.3-1c-0.1,0.3-0.1,0.6-0.2,0.9c-0.4,1-1.1,1.6-2.2,1.6c-1,0-1.7-0.4-2.2-1.5
			c-0.4-0.8-0.2-1.9,0.5-2.6c1.3-1.4,3.3-0.9,4,0.7C7.7,22.9,7.8,22.9,8,23c0.5,0.1,1,0.2,1.3,0.5c1.7,1.4,3.4,2.9,5,4.3
			C14.6,28.1,14.8,28.4,15.2,28.7z M6.4,23.6c0-0.6-0.5-1-1-1c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.2,1,1.2
			C5.9,24.8,6.4,24.2,6.4,23.6z M28.3,24.7c0.5,0,1.1-0.5,1.1-1.1c0-0.5-0.5-1.1-1.1-1.1c-0.6,0-1.2,0.5-1.2,1
			C27.1,24.1,27.7,24.7,28.3,24.7z M16.9,29.3c-0.5,0-1.1,0.5-1.1,1c0,0.6,0.4,1.1,1,1.1c0.6,0,1.2-0.6,1.2-1.2
			C18,29.8,17.5,29.4,16.9,29.3z"/>
                  <path d="M7.7,11.4c1-0.9,2.1-1.7,3.1-2.6c0.9-0.8,1.9-1.7,2.8-2.5c0.2-0.2,0.5-0.3,0.7-0.4c0.1-0.1,0.1-0.2,0.2-0.3
			c0.1-0.5,0-1.1,0.1-1.6c0.3-1,1.3-1.6,2.4-1.5c0.9,0,1.9,0.8,2.1,1.7c0.1,0.4,0,0.9,0,1.4c0,0.3-0.1,0.4,0.2,0.6
			c0.6,0.4,1.2,0.9,1.8,1.4c1,0.8,2.1,1.7,3.1,2.5c0.5,0.4,1,0.8,1.4,1.3c0.2-0.4,0.3-0.8,0.5-1.1c0.5-0.9,1.2-1.4,2.3-1.3
			c1,0.1,1.9,0.9,2.1,1.9c0.2,1-0.2,2-1,2.6c-1.2,0.8-2.8,0.4-3.5-0.9c0-0.1-0.1-0.2-0.2-0.2c-0.7-0.1-1.3-0.6-1.8-1
			c-1.6-1.2-3.1-2.5-4.6-3.8c-0.3-0.2-0.6-0.5-0.5-1c-0.2,0.1-0.2,0.1-0.3,0.2c-1,0.9-2.4,1-3.4,0c-0.2-0.1-0.2-0.2-0.4,0
			c-1.3,1.5-2.8,2.9-4.4,4.1c-0.4,0.3-0.9,0.7-1.3,1.1c-0.4,0.3-0.7,0.5-1.2,0.4c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.6-0.7,1.1-1.4,1.3
			c-1,0.4-1.7,0.2-2.4-0.7C2.4,11.5,2.9,9.5,4.7,9c0.8-0.2,2.1,0.2,2.5,1C7.4,10.5,7.5,11,7.7,11.4z M28,12.4c0.6,0,1.2-0.6,1.2-1.2
			c0-0.5-0.5-1-1.1-1c-0.5,0-1.1,0.6-1.1,1.2C27,11.9,27.5,12.4,28,12.4z M4.2,11.2c0,0.6,0.5,1.2,1,1.2c0.5,0,1.1-0.5,1.1-1
			c0-0.6-0.6-1.2-1.1-1.2C4.7,10.3,4.2,10.7,4.2,11.2z M16.7,5.8c0.7,0,1.1-0.4,1.1-1c0-0.6-0.4-1-0.9-1c-0.7,0-1.2,0.4-1.2,0.9
			C15.7,5.3,16.2,5.8,16.7,5.8z"/>
                  <path d="M25.5,15c-0.6,0.7-1,0.8-1.7,0.2c-0.9-0.8-1.7-1.6-2.6-2.4c-0.8-0.7-1.6-1.4-2.4-2.1c-0.3-0.2-0.7-0.4-1-0.4
			c-0.9,0-1.7,0-2.6,0.1c-0.3,0-0.5,0.2-0.7,0.4c-1.8,1.6-3.5,3.2-5.2,4.8c-0.1,0.1-0.4,0.2-0.5,0.2c-0.2-0.1-0.5-0.2-0.7-0.4
			c-0.1-0.1,0.1-0.4,0.3-0.6c1.7-1.5,3.5-2.9,5.1-4.5c0.8-0.8,1.7-1.1,2.8-1.1c0.7,0.1,1.4,0,2.2,0.2c0.3,0.1,0.7,0.3,1,0.6
			c1.9,1.6,3.9,3.3,5.8,4.9C25.4,14.9,25.5,14.9,25.5,15z"/>
                  <path d="M16.5,25.9c-0.7,0.1-1.5-0.3-2.2-0.8c-1.6-1.3-3.2-2.7-4.8-4c-0.4-0.3-0.8-0.6-1.2-0.9c-0.4-0.3-0.4-0.5,0-0.7
			c0.4-0.2,0.8-0.2,1.2,0.2c1.4,1.2,2.8,2.4,4.2,3.7c0.5,0.5,1.1,0.9,1.7,1.4c0.2,0.1,0.5,0.2,0.7,0.3c0.4,0,0.8,0,1.2,0
			c0.5,0,1-0.2,1.4-0.6c1.3-1.2,2.7-2.5,4-3.7c0.4-0.3,0.7-0.7,1.1-1c0.2-0.2,0.5-0.3,0.8-0.3c0.2,0,0.4,0.2,0.5,0.4
			c0.1,0.3-0.1,0.4-0.3,0.6c-0.6,0.4-1.2,0.7-1.7,1.2c-1.1,1-2.2,2-3.3,3.1C18.7,25.7,18.6,25.8,16.5,25.9z"/>
                  <path d="M12.1,29.4c0,0.5-0.3,0.9-0.6,0.9c-0.5,0-1-0.5-1-0.9c0-0.4,0.5-0.9,0.8-0.9C11.7,28.6,12.1,29,12.1,29.4z"/>
                  <path d="M9.3,6.5c0.2,0.2,0.5,0.4,0.6,0.6c0.1,0.1-0.1,0.4-0.1,0.5C9.7,7.7,9.5,7.8,9.4,7.9C9.2,8,9,8.4,8.7,8.1
			c-0.5-0.5-0.5-0.8,0-1.2C8.8,6.7,9,6.6,9.3,6.5z"/>
                  <path d="M9,26.9c0.4,0,0.7,0.4,0.7,0.8c0,0.3-0.3,0.7-0.6,0.7c-0.3,0-1-0.5-1-0.7C8.1,27.3,8.6,26.9,9,26.9z"/>
                  <path d="M22,4.8c0.4,0,0.8,0.4,0.8,0.7c0,0.3-0.6,0.9-0.8,0.9c-0.2,0-0.7-0.6-0.7-0.8C21.3,5.2,21.7,4.8,22,4.8z"/>
                  <path d="M10.4,5.7c0-0.3,0.5-0.8,0.9-0.8c0.3,0,0.6,0.4,0.6,0.9c0,0.3-0.5,0.6-0.7,0.6C10.9,6.4,10.4,6,10.4,5.7z"/>
                  <path d="M22.5,29.6c0,0.5-0.2,0.8-0.6,0.8c-0.4,0-0.7-0.3-0.6-0.7c0-0.4,0.3-0.8,0.6-0.8C22.2,28.8,22.5,29.1,22.5,29.6z"/>
                  <path d="M24.3,7.8c0-0.4,0.4-0.9,0.7-0.9c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.6,0.9-0.8,0.9C24.6,8.4,24.3,8.1,24.3,7.8z"/>
                  <path d="M25.2,28c-0.5,0-0.9-0.4-0.9-0.7c0-0.3,0.1-0.6,0.4-0.6c0.4,0,0.9,0.5,0.9,0.9C25.5,27.8,25.2,27.9,25.2,28z"/>
                  <path d="M23.6,28.9c-0.4,0-0.8-0.4-0.8-0.7c0-0.2,0.3-0.4,0.4-0.4c0.3-0.1,0.7,0.5,0.7,0.8C23.9,28.7,23.7,28.8,23.6,28.9z"/>
                  <path d="M24,6.3c0,0.5-0.2,0.8-0.5,1c-0.1,0.1-0.3,0.1-0.3,0c-0.1,0-0.1-0.2-0.1-0.3c0.1-0.3,0.3-0.6,0.5-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1C24,6.1,24,6.3,24,6.3z"/>
                  <path d="M14.8,17.5c0-1.2,1.1-2.1,2-2.1c1.1,0,2.1,1.1,2,2.2c0,1-1.1,2-2.1,1.9C15.7,19.5,14.8,18.5,14.8,17.5z M16.8,16.8
			c-0.4,0-0.6,0.2-0.6,0.6c0,0.4,0.3,0.8,0.6,0.8c0.4,0,0.7-0.4,0.8-0.8C17.6,17,17.3,16.8,16.8,16.8z"/>
              </g>
          </g>
          <path d="M38,21.5l0.2,1.3c0,0,0.6,0.3,1.6,0.3c1,0,1.9-0.4,1.9-1.3c0-1.9-5.4-1-5.4-5c0-2.2,1.7-3.4,4.1-3.4c1.8,0,3.5,0.6,3.5,0.6
	L43.9,17h-1.5L42,15.7c0,0-0.7-0.2-1.4-0.2c-1.1,0-1.7,0.5-1.7,1.1c0,1.9,5.4,1.1,5.4,4.9c0,2.4-1.9,3.7-4.3,3.7
	c-1.8,0-3.6-0.6-3.6-0.6v-3.1C36.4,21.5,38,21.5,38,21.5z M51.1,16.1c2.6,0,4,1.6,4,4.3c0,2.9-1.7,4.6-4.3,4.6
	c-2.6,0-4.1-1.4-4.1-4.4C46.7,17.8,48.5,16.1,51.1,16.1z M51,18.1c-1.2,0-1.8,0.6-1.8,2.6c0,1.8,0.5,2.6,1.7,2.6
	c1.2,0,1.7-0.7,1.7-2.6C52.6,18.9,52.1,18.1,51,18.1z M57.1,16.4l3.4-0.1l0.1,0.9c0,0,1.1-1.1,2.4-1.1c2.2,0,3.3,1.6,3.3,4.2
	c0,3-1.4,4.7-4.2,4.7c-0.5,0-0.9-0.1-1.4-0.2v2l1.5,0.4v1.3h-5.2v-1.3l1.2-0.4v-8.9l-1.3-0.4C57.1,17.7,57.1,16.4,57.1,16.4z
	 M60.8,18.9V23c0.3,0.1,0.6,0.2,1.2,0.2c1.4,0,1.8-1,1.8-2.6c0-1.6-0.4-2.6-1.6-2.6C61.2,18.1,60.8,18.9,60.8,18.9z M72.1,12.5v4.7
	c0.5-0.4,1.5-1.1,2.7-1.1c1.6,0,2.5,1.1,2.5,2.9v4.2l1.1,0.4v1.3h-4.4v-1.3l0.8-0.4v-3.9c0-0.8-0.3-1.3-1.1-1.3
	c-0.9,0-1.7,0.8-1.7,0.8v4.3l0.8,0.4v1.3h-4.5v-1.3l1.3-0.4v-9L68.4,14v-1.3L72.1,12.5z M80.5,24.9v-1.4l1.2-0.5v-7.6l-1.2-0.5v-1.3
	l4.9-0.1v1.4l-1.2,0.5v2.8h1.1l2.7-4.6l3-0.1v1.4l-1.5,0.4L87.3,19l3,4.1l1.2,0.5v1.4h-4.9v-1.4l1-0.5l-2.2-3h-1.2v3l1.2,0.5v1.4
	C85.4,24.9,80.5,24.9,80.5,24.9z M97.4,16.1c2.6,0,4,1.6,4,4.3c0,2.9-1.7,4.6-4.3,4.6c-2.6,0-4.1-1.4-4.1-4.4
	C93.1,17.8,94.8,16.1,97.4,16.1z M97.4,18.1c-1.2,0-1.8,0.6-1.8,2.6c0,1.8,0.5,2.6,1.7,2.6c1.2,0,1.7-0.7,1.7-2.6
	C99,18.9,98.5,18.1,97.4,18.1z M105.2,22.1l0.1,1c0,0,0.6,0.2,1.3,0.2c0.9,0,1.3-0.3,1.3-0.8c0-1.3-4.3-0.8-4.3-3.7
	c0-2,1.6-2.7,3.3-2.7c1.4,0,3,0.5,3,0.5l-0.1,2.4h-1.4l-0.2-1c0,0-0.5-0.2-1.1-0.2c-0.7,0-1.1,0.3-1.1,0.8c0,1.3,4.3,0.7,4.3,3.5
	c0,1.8-1.5,2.9-3.7,2.9c-1.5,0-2.9-0.4-2.9-0.4v-2.6H105.2z M25.4-2.7"/>
      </svg>
    </SvgIcon>
  );
}
