import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const styles = {
    icon: {
    
    }
}
export default function DDIcon() {
  return (
    <SvgIcon sx={{ height: 36, width: 232, mr: 2 }}>
      <svg
            viewBox="0 0 148.979 49.404"
        
        xmlns="http://www.w3.org/2000/svg"
      >
          <g id="Layer_1">
              <path id="Path_37740" d="M110.926,66.812a7.379,7.379,0,0,0-1.187-2.687A5.513,5.513,0,0,0,106.9,62.01a8.777,8.777,0,0,0-2.58-.362H97.13v1.933a11.8,11.8,0,0,1,1.447,1.24,8.56,8.56,0,0,1,1.86,2.625l.062.151V64.54h3.216q2.377,0,3.177,1.36a7.784,7.784,0,0,1,.8,3.909,8.549,8.549,0,0,1-.531,3.177,3.472,3.472,0,0,1-3.444,2.431h-3.2v-4.88a3.992,3.992,0,0,1-.777,1.065l-2.274,2.274-.331-.329v4.767h7.187A6.007,6.007,0,0,0,109.9,75.2a11.043,11.043,0,0,0,1.337-5.631A12.949,12.949,0,0,0,110.926,66.812Z" transform="translate(-77.052 -48.905)" fill="#000000"/>
              <path id="Path_37741" d="M58.631,57.955a9.314,9.314,0,0,0-1.116-2.54,13.23,13.23,0,0,0-1.92-2.388q-.26-.26-.546-.509a13.617,13.617,0,0,0-1.631-1.22,7.442,7.442,0,0,0-2.743-1.062,5.5,5.5,0,0,0-3.514.515,8.739,8.739,0,0,0-2.082,1.567L40,57.4,51.783,69.183,56.864,64.1A6,6,0,0,0,58.631,57.955Zm-3.44,3.456a3.992,3.992,0,0,1-.777,1.065L52.14,64.749l-.331-.329-7.363-7.365,2.274-2.274Q48.4,53.1,49.922,53.5a5.776,5.776,0,0,1,1.887.957,11.8,11.8,0,0,1,1.447,1.24,8.56,8.56,0,0,1,1.86,2.625l.062.151A3.505,3.505,0,0,1,55.191,61.411Z" transform="translate(-31.732 -39.778)" fill="#ff931e"/>
              <path id="Path_37742" d="M21.988,49.146A51.929,51.929,0,0,1,9.116,37.012,48.239,48.239,0,0,1,2.722,24.938,43.227,43.227,0,0,1,0,11.667v-.537L8.694,0H36.073l8.694,11.129v.537a43.066,43.066,0,0,1-2.722,13.271,48.232,48.232,0,0,1-6.392,12.074A51.759,51.759,0,0,1,22.78,49.146l-.395.258ZM3.121,12.171v.076a42.635,42.635,0,0,0,2.536,11.7A45.154,45.154,0,0,0,22.265,45.584l.116.083.116-.083a45.142,45.142,0,0,0,16.628-21.7,42.344,42.344,0,0,0,2.514-11.638v-.076L34.562,3.1H10.207Z" fill="#000000"/>
              <path id="Path_37743" d="M285.853,63.687a5.91,5.91,0,0,1,3.053,2.274,7.946,7.946,0,0,1,1.28,2.894,13.861,13.861,0,0,1,.341,2.966,11.911,11.911,0,0,1-1.437,6.057,6.482,6.482,0,0,1-6.013,3.343H275.35V63.3h7.727A9.509,9.509,0,0,1,285.853,63.687Zm-6.867,2.724V78.12h3.458a3.736,3.736,0,0,0,3.7-2.615,9.2,9.2,0,0,0,.573-3.417,8.4,8.4,0,0,0-.858-4.2q-.858-1.464-3.417-1.466Z" transform="translate(-218.432 -50.214)" fill="#ff931e"/>
              <path id="Path_37744" d="M364.754,65.968H361.24v-3.2h3.514Zm-3.514,1.581h3.514V80.8H361.24Z" transform="translate(-286.567 -49.795)" fill="#000000"/>
              <path id="Path_37745" d="M397.911,84.667a4.172,4.172,0,0,1,2.04,1.922V84.68h3.382V97.254a7.036,7.036,0,0,1-.864,3.868q-1.486,2.239-5.691,2.239a7.754,7.754,0,0,1-4.149-1,3.676,3.676,0,0,1-1.774-2.983h3.768a1.555,1.555,0,0,0,.475.874,2.921,2.921,0,0,0,1.885.475,2.533,2.533,0,0,0,2.505-1.253,6.342,6.342,0,0,0,.413-2.7v-.852a4.228,4.228,0,0,1-1.071,1.277,4.305,4.305,0,0,1-2.687.79,5.085,5.085,0,0,1-4.081-1.794,7.255,7.255,0,0,1-1.526-4.858,8.165,8.165,0,0,1,1.47-4.961,4.879,4.879,0,0,1,4.165-2.013A4.547,4.547,0,0,1,397.911,84.667Zm1.18,9.509a4.223,4.223,0,0,0,.827-2.925,4.491,4.491,0,0,0-.792-2.869,2.59,2.59,0,0,0-2.119-.992,2.506,2.506,0,0,0-2.505,1.716,6.107,6.107,0,0,0-.353,2.251,5.145,5.145,0,0,0,.387,2.055,2.523,2.523,0,0,0,2.52,1.679,2.658,2.658,0,0,0,2.036-.918Z" transform="translate(-309.811 -66.925)" fill="#000000"/>
              <path id="Path_37746" d="M471.824,65.968H468.31v-3.2h3.514Zm-3.514,1.581h3.514V80.8H468.31Z" transform="translate(-371.504 -49.795)" fill="#000000"/>
              <path id="Path_37747" d="M513.4,63.687a5.916,5.916,0,0,1,3.055,2.274,7.907,7.907,0,0,1,1.278,2.894,13.865,13.865,0,0,1,.341,2.966,11.925,11.925,0,0,1-1.435,6.057,6.471,6.471,0,0,1-6.013,3.343H502.9V63.3h7.729A9.483,9.483,0,0,1,513.4,63.687Zm-6.867,2.724V78.12H510a3.736,3.736,0,0,0,3.7-2.615,9.2,9.2,0,0,0,.573-3.417,8.4,8.4,0,0,0-.858-4.2q-.858-1.464-3.417-1.466Z" transform="translate(-398.944 -50.214)" fill="#000000"/>
              <path id="Path_37748" d="M596.444,95.921a7.314,7.314,0,0,1-10.191,0,8.211,8.211,0,0,1,0-9.949,7.237,7.237,0,0,1,10.191-.008,7.732,7.732,0,0,1,1.678,4.961A7.619,7.619,0,0,1,596.444,95.921Zm-2.774-1.914a5.07,5.07,0,0,0,.827-3.074,4.853,4.853,0,0,0-.827-3.066,2.725,2.725,0,0,0-2.326-1.077A2.77,2.77,0,0,0,589,87.868a6.112,6.112,0,0,0,0,6.139,3.072,3.072,0,0,0,4.676,0Z" transform="translate(-463.734 -66.533)" fill="#000000"/>
              <path id="Path_37749" d="M666.8,84.807a5.407,5.407,0,0,1,2.286,1.962,6.531,6.531,0,0,1,1.054,2.741,16.727,16.727,0,0,1,.116,2.629h-9.645a3.175,3.175,0,0,0,1.387,2.8,3.514,3.514,0,0,0,1.91.5,2.687,2.687,0,0,0,2.638-1.521h3.539a4.307,4.307,0,0,1-1.284,2.4,6.442,6.442,0,0,1-4.988,1.935,7.235,7.235,0,0,1-4.67-1.631q-2.022-1.631-2.022-5.308a7.178,7.178,0,0,1,1.825-5.281,6.389,6.389,0,0,1,4.74-1.838A7.555,7.555,0,0,1,666.8,84.807Zm-5.184,3a3.6,3.6,0,0,0-.924,2.051h5.972a2.884,2.884,0,0,0-.924-2.094,3.028,3.028,0,0,0-2.055-.717A2.745,2.745,0,0,0,661.614,87.8Z" transform="translate(-521.285 -66.784)" fill="#000000"/>
          </g>
      </svg>
    </SvgIcon>
  );
}
